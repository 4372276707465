
import apiFetch from "@/utils/request";
import { AxiosResponse } from "axios";
import { Vue } from "vue-class-component";

class AppComponent extends Vue {
  async logout() {
    const response: AxiosResponse<{ success: boolean }> =
      await apiFetch.auth.logout();
    if (response?.data?.success) {
      this.$store.state.isLoggedIn = false;
    }
  }
  created() {
    this.checkSessionStatus().then(() => {
      if (this.$store.state.isLoggedIn !== true) {
        this.$router.push({ path: "/auth/login" });
      }
    });
  }
  async checkSessionStatus() {
    const response: AxiosResponse<{ status: boolean }> =
      await apiFetch.auth.checkSessionStatus();
    this.$store.state.isLoggedIn = response?.data?.status;
  }
}
export default AppComponent;
