<template>
  <div class="home">
    <h1>General API Gateway Admin Panel App</h1>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

class Home extends Vue {}
export default Home;
</script>
