import axios from "axios";
import {
  ApiEndpoint,
  OauthClient,
  CloudStorage,
  FrontendClientRequest,
} from "../types/index";
const api = axios.create({
  baseURL: process.env.WEB_URL,
  withCredentials: true,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
const prefix = "/cms/v1";
const apiFetch = {
  auth: {
    login(payload: { username: string; password: string }) {
      return api.post("/api/login", payload);
    },
    logout() {
      return api.post("/api/logout");
    },
    checkSessionStatus() {
      return api.get("/api/session-status");
    },
  },
  apiEndpoint: {
    getApiEndpointsList() {
      return api.get(`${prefix}/api-endpoints`);
    },
    getApiEndpointDetail(id: number) {
      return api.get(`${prefix}/api-endpoints/${id}`);
    },
    createApiEndpoint(payload: ApiEndpoint) {
      return api.post(`${prefix}/api-endpoints`, payload);
    },
    updateApiEndpointDetail(id: number, payload: ApiEndpoint) {
      return api.put(`${prefix}/api-endpoints/${id}`, payload);
    },
    deleteApiEndpoint(id: number) {
      return api.delete(`${prefix}/api-endpoints/${id}`);
    },
  },
  oauthClient: {
    getOauthClientsList() {
      return api.get(`${prefix}/oauth-clients`);
    },
    getOauthClientDetail(id: number) {
      return api.get(`${prefix}/oauth-clients/${id}`);
    },
    createOauthClient(payload: OauthClient) {
      return api.post(`${prefix}/oauth-clients`, payload);
    },
    updateOauthClientDetail(id: number, payload: OauthClient) {
      return api.put(`${prefix}/oauth-clients/${id}`, payload);
    },
    deleteOauthClient(id: number) {
      return api.delete(`${prefix}/oauth-clients/${id}`);
    },
  },
  cloudStorage: {
    getCloudStorageList() {
      return api.get(`${prefix}/cloud-storage`);
    },
    getCloudStorageDetail(id: number) {
      return api.get(`${prefix}/cloud-storage/${id}`);
    },
    createCloudStorage(payload: CloudStorage) {
      return api.post(`${prefix}/cloud-storage`, payload);
    },
    updateCloudStorageDetail(id: number, payload: CloudStorage) {
      return api.put(`${prefix}/cloud-storage/${id}`, payload);
    },
    deleteCloudStorage(id: number) {
      return api.delete(`${prefix}/cloud-storage/${id}`);
    },
  },
  frontendClient: {
    getFrontendClientsList() {
      return api.get(`${prefix}/frontend-clients`);
    },
    getFrontendClientDetail(id: number) {
      return api.get(`${prefix}/frontend-clients/${id}`);
    },
    createFrontendClient(payload: FrontendClientRequest) {
      return api.post(`${prefix}/frontend-clients`, payload);
    },
    updateFrontendClientDetail(id: number, payload: FrontendClientRequest) {
      return api.put(`${prefix}/frontend-clients/${id}`, payload);
    },
    deleteFrontendClient(id: number) {
      return api.delete(`${prefix}/frontend-clients/${id}`);
    },
  },
};
export default apiFetch;
