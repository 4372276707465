import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/cloud-storage",
    name: "CloudStorage",
    component: () => import("../views/cloud-storage/index.vue"),
  },
  {
    path: "/api-endpoint",
    name: "ApiEndpoint",
    component: () => import("../views/api-endpoint/index.vue"),
  },
  {
    path: "/oauth-client",
    name: "OauthClient",
    component: () => import("../views/oauth-client/index.vue"),
  },
  {
    path: "/frontend-client",
    name: "FrontendClient",
    component: () => import("../views/frontend-client/index.vue"),
  },
  {
    path: "/cloud-storage/create",
    name: "CreateCloudStorage",
    component: () => import("../views/cloud-storage/create.vue"),
  },
  {
    path: "/api-endpoint/create",
    name: "CreateApiEndpoint",
    component: () => import("../views/api-endpoint/create.vue"),
  },
  {
    path: "/oauth-client/create",
    name: "CreateOauthClient",
    component: () => import("../views/oauth-client/create.vue"),
  },
  {
    path: "/frontend-client/create",
    name: "CreateFrontendClient",
    component: () => import("../views/frontend-client/create.vue"),
  },
  {
    path: "/cloud-storage/:id", // TODO: change to id later
    name: "EditCloudStorage",
    component: () => import("../views/cloud-storage/edit.vue"),
  },
  {
    path: "/api-endpoint/:id",
    name: "EditApiEndpoint",
    component: () => import("../views/api-endpoint/edit.vue"),
  },
  {
    path: "/oauth-client/:id",
    name: "EditOauthClient",
    component: () => import("../views/oauth-client/edit.vue"),
  },
  {
    path: "/frontend-client/:id",
    name: "EditFrontendClient",
    component: () => import("../views/frontend-client/edit.vue"),
  },
  {
    path: "/auth/login",
    name: "AuthLogin",
    component: () => import("../views/auth/login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.WEB_URL),
  routes,
});

export default router;
