<template>
  <div>
    <div v-if="$store.state.isLoggedIn" id="nav">
      <router-link to="/"> Home </router-link>
      <router-link to="/api-endpoint"> API Endpoint </router-link>
      <router-link to="/cloud-storage"> Cloud Storage </router-link>
      <router-link to="/oauth-client"> OAuth Client </router-link>
      <router-link to="/frontend-client"> Frontend Client </router-link>
      <router-link to="/auth/login" @click="logout"> Logout </router-link>
    </div>
    <router-view />
  </div>
</template>

<script lang="ts">
import apiFetch from "@/utils/request";
import { AxiosResponse } from "axios";
import { Vue } from "vue-class-component";

class AppComponent extends Vue {
  async logout() {
    const response: AxiosResponse<{ success: boolean }> =
      await apiFetch.auth.logout();
    if (response?.data?.success) {
      this.$store.state.isLoggedIn = false;
    }
  }
  created() {
    this.checkSessionStatus().then(() => {
      if (this.$store.state.isLoggedIn !== true) {
        this.$router.push({ path: "/auth/login" });
      }
    });
  }
  async checkSessionStatus() {
    const response: AxiosResponse<{ status: boolean }> =
      await apiFetch.auth.checkSessionStatus();
    this.$store.state.isLoggedIn = response?.data?.status;
  }
}
export default AppComponent;
</script>

<style lang="scss">
/*每个页面公共css */
@import "scss/app.scss";
</style>
